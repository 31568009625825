import {
  dateWithSlash,
  dataWithDash,
  timeWithColon,
  sliceTimeWithColon,
} from "../../utils/numberFormat"

export const orderReader000 = (api: any, isCancel: boolean) => {
  const payment = api.order.payment

  const paymentMethod = api.order.payment.way || "NONE"
  const items = api.order.items
  const history = api.session.history

  const paymentReader = (paymentMethod: any) => {
    // wallet 잔액 결제 타입
    if (payment.type === 3) {
      const { history } = api.session
      const [paymentResult] = history.filter(
        ({ action }) => action === "SET_WALLET_PAYMENT_RESULT"
      )
      const {
        payload: { result },
      } = paymentResult

      const vatAmount = Number(result.tax)
      const cardNum = result.cardNumber
      const tradeDate = dataWithDash(result.date)
      const tradeTime = sliceTimeWithColon(result.time)

      return {
        otherPayment: result,
        vatAmount,
        tradeDate,
        tradeTime,
        cardNum,
      }
    }
    if (paymentMethod === "PAYCO_BARCODE") {
      const paycoPayment = payment.paycoStatus.msg
      const { successMsg, cancelMsg } = paycoPayment
      const { approvalResultList, totalVatAmount, tradeDatetime } = successMsg

      // const
      const vatAmount = totalVatAmount
      const tradeDate = dataWithDash(tradeDatetime)
      const tradeTime = sliceTimeWithColon(tradeDatetime)

      const paymentResult = {
        cancelMsg,
        approvalResultList,
        vatAmount,
        tradeDate,
        tradeTime,
      }

      return paymentResult
    } else if (paymentMethod === "CREDIT_CARD") {
      console.log(payment)
      if (!payment.status.result) {
        return {
          vatAmount: "0",
          tradeDate: "",
          tradeTime: "",
          cardNum: "테스트결제",
        }
      }
      const otherPayment = payment.status.result.successMsg

      const vatAmount = Number(otherPayment.tax)
      const cardNum = otherPayment.cardNumber
      const tradeDate = dataWithDash(otherPayment.date)
      const tradeTime = sliceTimeWithColon(otherPayment.time)

      const paymentResult = {
        otherPayment,
        vatAmount,
        tradeDate,
        tradeTime,
        cardNum,
      }

      return paymentResult
    } else if (paymentMethod === "NONE") {
      const resultHistory = history.find(
        h => h.action === "SET_WALLET_PAYMENT_RESULT"
      )
      if (!resultHistory)
        throw Error(
          "비결제 모드 영수증 발행이지만, session에 결제정보가 없습니다."
        )
      const result = resultHistory.payload.result
      return {
        vatAmount: Number(result.tax),
        tradeDate:
          result.date === "테스트_날짜"
            ? "테스트_날짜"
            : dataWithDash(result.date),
        tradeTime:
          result.time === "테스트_시간"
            ? "테스트_시간"
            : sliceTimeWithColon(result.time),
        cardNum: result.cardNumber,
      }
    }
  }

  // history 내 존재하는 멤버십 데이터 가공
  const filteringAction = (action: any) => {
    return history.find((i: any) => i.action === action)
  }

  const isMember = filteringAction("SIGN_IN_SUCCESS")
  const membersInfo =
    isMember && filteringAction("SIGN_IN_SUCCESS").payload.userData
  const membersNum = isMember && membersInfo.phoneNum.slice(-4)
  const couponAmount = isMember && membersInfo.availableMembershipCouponNum
  const sticker = isMember && membersInfo.availableStickerNum
  const isCouponUsed = filteringAction("USE_COUPON") !== undefined
  const couponPrice =
    isCouponUsed && filteringAction("USE_COUPON").payload.totalCouponPrice

  // item 가공
  let itemInfoTable: any = {}
  items.forEach((item: any) => {
    const { recipeName, price, selectedOptions } = item
    const { basic, concnCharge, sizeCharge } = price
    const addedOptionPrice = basic + concnCharge + sizeCharge
    if (!itemInfoTable[recipeName]) {
      itemInfoTable = {
        ...itemInfoTable,
        [recipeName]: {
          cnt: 0,
          totalPrice: 0,
          basicPrice: basic,
          selectedOptions,
        },
      }
    }
    itemInfoTable[recipeName].cnt = itemInfoTable[recipeName].cnt + 1
    itemInfoTable[recipeName].totalPrice =
      itemInfoTable[recipeName].totalPrice + addedOptionPrice
  })
  const itemsTotal = Object.values(itemInfoTable)
    .map((recipe: any) => recipe.totalPrice)
    .reduce((acc, cur) => acc + cur)
  const paymentSummary = filteringAction("SET_PAYMENT_SUMMARY")

  let subTotal = 0
  // wallet 결제의 경우, totalPrice가 balance
  if (payment.type === 3) {
    const { history } = api.session
    const [balanceResult] = history.filter(
      ({ action }) => action === "SET_WALLET_BALANCE_PAYMENT"
    )
    const {
      payload: { balance },
    } = balanceResult
    subTotal = balance
  } else {
    subTotal = paymentSummary ? paymentSummary.payload.totalPrice : 0
  }

  // 기타 전처리
  const minusText = isCancel && "-"
  const issueReceiptTimeStamp = api.session.timeStamp._seconds * 1000
  const issueReceiptTime = new Date(issueReceiptTimeStamp)
  const approvalDate = dateWithSlash(issueReceiptTime)
  const approvalTime = timeWithColon(issueReceiptTime)

  const reader000 = {}
  const result = {
    ...reader000,
    minusText,
    approvalDate,
    approvalTime,
    itemsTotal,
    itemInfoTable,
    isMember,
    membersNum,
    couponAmount,
    sticker,
    couponPrice,
    isCouponUsed,
    subTotal,
    paymentMethod,
    paymentReader,
  }
  return result
}
