import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import axios from "axios"
import { numberWithCommas } from "../utils/numberFormat"
import * as queryString from "query-string"
import { orderReader000 } from "./orderReaders/orderReader000"

import PaycoPayment from "../components/receipt/PaycoPayment"
import CreditCardPayment from "../components/receipt/CreditCardPayment"

const Receipt = ({ location }: any) => {
  const { id, vm, year, month, status } = queryString.parse(location.search)
  const isCancel = status === "cancel"
  const [api, setApi] = useState<any>({})
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    axios
      .post(
        "https://us-central1-planz-6761f.cloudfunctions.net/receipt",
        null,
        { params: { id, vm, year, month } }
      )
      .then(res => setApi(res.data))
      .catch(() => setIsError(true))
  }, [])

  if (Object.keys(api).length === 0) {
    if (isError) {
      return (
        <div css={align__center}>
          <p css={receipt__error}>
            Page not found. <br />
            <br />
            해당 거래 내역이 없습니다.
          </p>
        </div>
      )
    }
    return (
      <div css={align__center}>
        <p css={receipt__error}>로딩중입니다. ☕︎</p>
      </div>
    )
  }

  // versioning code
  const dataVersions = [
    { version: "1.2.1", reader: orderReader000 },
    { version: "1.2.2", reader: orderReader000 },
    { version: "1.2.3", reader: orderReader000 },
  ]
  const foundReader: any = dataVersions.find(
    (dv: any) => dv.version === api.session.version
  )

  if (!foundReader || foundReader.reader === undefined) {
    return (
      <div css={align__center}>
        <p css={receipt__error}>
          영수증 처리 과정 중
          <br />
          문제가 발생했습니다. <br />
          <br />
          아래 번호로 관리자에게 문의하시기 바랍니다.
          <br />☎ 대표번호: 1644-6513
        </p>
      </div>
    )
  }
  const readResult = foundReader.reader(api, isCancel)
  const paymentResult = readResult.paymentReader(readResult.paymentMethod)

  if (isCancel && !readResult.cancelMsg) {
    return <div>해당 거래건에 대한 취소내역이 없습니다.</div>
  }

  return (
    <div css={receipt__bg}>
      <div css={align__center}>
        <section css={container}>
          <CompanyInfo
            company={api.company}
            approvalDate={readResult.approvalDate}
            approvalTime={readResult.approvalTime}
          />
          <ItemList
            itemsTotal={readResult.itemsTotal}
            itemInfoTable={readResult.itemInfoTable}
            isCouponUsed={readResult.isCouponUsed}
            couponPrice={readResult.couponPrice}
            minusText={readResult.minusText}
          />

          <article css={paymentInfo__container}>
            <SubTotal
              subTotal={readResult.subTotal}
              vatAmount={paymentResult.vatAmount}
              minusText={readResult.minusText}
            />
            {readResult.paymentMethod === "PAYCO_BARCODE" ? (
              <PaycoPayment
                minusText={readResult.minusText}
                isCancel={isCancel}
                approvalResultList={paymentResult.approvalResultList}
                tradeDate={paymentResult.tradeDate}
                tradeTime={paymentResult.tradeTime}
              />
            ) : (
              <CreditCardPayment
                minusText={readResult.minusText}
                isCancel={isCancel}
                cardNumber={paymentResult.cardNum}
                tradeDate={paymentResult.tradeDate}
                tradeTime={paymentResult.tradeTime}
                subTotal={readResult.subTotal}
              />
            )}
          </article>
          {readResult.isMember && (
            <Membership
              couponAmount={readResult.couponAmount}
              membersNum={readResult.membersNum}
              sticker={readResult.sticker}
              isCouponUsed={readResult.isCouponUsed}
            />
          )}
        </section>
      </div>
    </div>
  )
}

interface CompanyInfoProps {
  approvalDate: string
  approvalTime: string
  company: {
    nameEng: string
    nameKor: string
    tel: string
    addr: string
    ceo: string
    bin: string
  }
}

export interface PaycoPaymentProps {
  isCancel: boolean
  approvalResultList: any
  tradeDate: string
  tradeTime: string
  minusText: string
}

export interface CreditCardPaymentProps {
  minusText: string
  isCancel: boolean
  tradeDate: string
  tradeTime: string
  cardNumber: string
  subTotal: string
}

const CompanyInfo = ({
  company,
  approvalDate,
  approvalTime,
}: CompanyInfoProps) => {
  return (
    <>
      <div css={title}>{company.nameEng}</div>
      <div css={branInfo__wrapper}>
        <div css={wrap}>
          <span>{company.nameKor}</span>
          <span>T: {company.tel}</span>
        </div>
        <span>{company.addr}</span>
        <span></span>
        <div css={wrap}>
          <span>대표: {company.ceo}</span>
          <span>{company.bin}</span>
        </div>
        <div css={wrap}>
          <span>
            {approvalDate} {approvalTime}
          </span>
        </div>
      </div>
    </>
  )
}

interface itemListProps {
  itemsTotal: any
  itemInfoTable: any
  minusText: string
  isCouponUsed: boolean
  couponPrice: number
}

const ItemList = ({
  itemsTotal,
  itemInfoTable,
  minusText,
  isCouponUsed,
  couponPrice,
}: itemListProps) => {
  return (
    <table css={item__list__table}>
      <thead css={list__thead}>
        <tr>
          <th>음료명</th>
          <th>단가</th>
          <th>수량</th>
          <th>금액</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(itemInfoTable).map((recipeName: any, i: number) => {
          const {
            cnt,
            basicPrice,
            totalPrice,
            selectedOptions,
          } = itemInfoTable[recipeName]
          return (
            <tr key={i}>
              <td>
                {selectedOptions.사이즈}){recipeName}, {selectedOptions.진하기}
              </td>
              <td css={text__center}>
                {minusText}
                {numberWithCommas(basicPrice)}
              </td>
              <td css={text__center}>{cnt}</td>
              <td css={text__center}>
                {}
                {minusText}
                {numberWithCommas(totalPrice)}
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot css={list__tfoot}>
        <tr>
          <td colSpan={2}>합계</td>
          <td></td>
          <td css={text__center}>
            {minusText}
            {numberWithCommas(itemsTotal)}
          </td>
        </tr>
        {isCouponUsed && (
          <tr>
            <td colSpan={2}>쿠폰</td>
            <td css={text__center}>(-)</td>
            <td css={text__center}>{numberWithCommas(couponPrice)}</td>
          </tr>
        )}
      </tfoot>
    </table>
  )
}

interface SubTotalProps {
  subTotal: number
  vatAmount: number
  minusText: string
}

const SubTotal = ({ subTotal, minusText, vatAmount }: SubTotalProps) => {
  return (
    <div css={container__line}>
      <div css={subTotal__text}>
        <span>결제금액</span>
        <span>
          {minusText}
          {numberWithCommas(subTotal)} 원
        </span>
      </div>
      <div css={text__margin}>
        <span>(VAT 포함)</span>
        <span>
          {minusText}({numberWithCommas(vatAmount)})
        </span>
      </div>
    </div>
  )
}

interface MembershipProps {
  membersNum: number
  couponAmount: number
  sticker: number
  isCouponUsed: boolean
}

const Membership = ({
  membersNum,
  couponAmount,
  sticker,
  isCouponUsed,
}: MembershipProps) => {
  return (
    <>
      <article css={members__container}>
        <div>
          ☕︎ <span>{membersNum}님</span> 멤버십 현황
        </div>
        <div>* 쿠폰: {!isCouponUsed ? couponAmount : couponAmount - 1}개</div>
        <div>* 스티커: {sticker}개</div>
      </article>
    </>
  )
}

const receipt__bg = css`
  width: 100%;
  height: 100vh;
  background-color: rgba(49, 40, 33, 0.164);
  z-index: -1;
  font-family: "Spoqa Han Sans";
`

const container = css`
  margin: 5vh 0;
  padding: 2vh 5vw;
  border-radius: 6px;
  background-color: white;
  box-shadow: 7px 7px 15px 3px rgb(177, 177, 177);
  width: 16rem;
  min-width: 15rem;
  font-size: 10px;
  line-height: 150%;

  @media (min-width: 768px) {
    width: 23rem;
    padding: 2vh 3vw;
    font-size: 15px;
  }
`

const receipt__error = css`
  margin: 5vh 0;
  padding: 2vh 5vw;
  border-radius: 6px;
  background-color: white;
  box-shadow: 7px 7px 15px 3px rgb(177, 177, 177);
  line-height: 150%;
  text-align: center;
  font-size: 0.7rem;
  font-family: "Spoqa Han Sans";
  font-weight: normal;

  @media (min-width: 768px) {
    width: 32rem;
    padding: 2vh 3vw;
    font-size: 1.5rem;
  }
`

const align__center = css`
  display: flex;
  justify-content: center;
`

const title = css`
  padding: 1.8vh 0;
  text-align: center;
  border-bottom: 1.5px solid black;
  font-size: 1.1rem;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`

const branInfo__wrapper = css`
  display: flex;
  flex-direction: column;
  padding: 0.6vh 0;
  font-weight: normal;
`

const wrap = css`
  display: flex;
  justify-content: space-between;
`

const item__list__table = css`
  width: 100%;
  border-top: 3px double black;
`

const list__thead = css`
  border-bottom: 1px dashed grey;
  padding: 3vh 0.5rem;
  font-size: 9px;
  line-height: 130%;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`

const list__tfoot = css`
  margin: 5px 0;
  font-weight: bold;
  font-size: 11px;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`

const paymentInfo__container = css`
  margin: 5px 0;
  padding-bottom: 5px;
  border-bottom: 1px dashed black;
`

const subTotal__text = css`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px dashed black;
  font-weight: 600;
  font-size: 15px;

  @media (min-width: 768px) {
    font-size: 21px;
    margin-top: 10px;
    padding-top: 10px;
  }
`

const container__line = css`
  border-bottom: 1px dashed black;
`

const members__container = css`
  padding: 5px 8px;
  span {
    font-weight: 600;
  }
`

const text__margin = css`
  display: flex;
  justify-content: space-between;
  margin: 2px 15px 5px 5px;
`

const text__center = css`
  text-align: center;
`

export default Receipt
