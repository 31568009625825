import React from "react"
import { css } from "@emotion/core"
import { PaycoPaymentProps } from "../../containers/receipt"
import { numberWithCommas } from "../../utils/numberFormat"

const PaycoPayment = ({
  isCancel,
  approvalResultList,
  tradeDate,
  tradeTime,
  minusText,
}: PaycoPaymentProps) => {
  return (
    <>
      <div css={payco__text__container}>
        {approvalResultList.map((result: any, i: number) => {
          const { approvalAmount, paymentMethodName } = result
          if (approvalAmount === 0) {
            return
          }
          return (
            <div css={wrap} key={i}>
              <p>{paymentMethodName}</p>
              <p>
                {minusText}
                {numberWithCommas(approvalAmount)}
              </p>
            </div>
          )
        })}
      </div>
      <h1 css={text__center}>
        {isCancel ? "***취소승인정보(고객용)***" : "***승인정보(고객용)***"}
      </h1>
      <div css={wrap}>
        <p>[결제방법] </p>
        <p>PAYCO</p>
      </div>
      <div css={wrap}>
        <p>[승인기관] </p>
        <p>
          {
            approvalResultList[approvalResultList.length - 1]
              .approvalCompanyName
          }
        </p>
      </div>
      <div css={wrap}>
        <p>[승인번호] </p>
        <p>{approvalResultList[approvalResultList.length - 1].approvalNo}</p>
      </div>
      <div css={wrap}>
        <p>[승인일시] </p>
        <p>{`${tradeDate} ${tradeTime}`}</p>
      </div>
      <div css={wrap}>
        <p>[가맹번호] </p>
        <p>2178114493</p>
      </div>
    </>
  )
}

export default PaycoPayment

const text__center = css`
  text-align: center;
`
const wrap = css`
  display: flex;
  justify-content: space-between;
`
const payco__text__container = css`
  padding: 7px 0;
  border-bottom: 1px dashed black;
  font-weight: bold;
`
